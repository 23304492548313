@use "../../mixin.scss" as *;
@use "../../variable.scss" as *;
.homePage {
    @include media501-min {
        display: none;
    }

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 80px;

    @include media500 {

        h1 {
            padding: 0px 17px 0px 30px;
            margin-top: 0px;
            font-size: 32px;
            line-height: 1.2;
            font-weight: 700;
        }


        &-img {
            margin-top: 20px;
            width: 100%;
            height: auto;
        }

        p {
            text-align: center;
            margin-top: 20px;
            max-width: 313px;
            width: 100%;
            font-weight: 400;
            font-size: 14px;
        }

        button {
            max-width: 162px;
            width: 100%;
            margin-top: 28px;
            font-size: 14px;
            font-weight: 700;
            padding: 8.04px 0px;
            line-height: 1.71;
            border-radius: 50px;
        }
    }

    .hideBtn {
        display: none;
    }
}