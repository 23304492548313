@use "../../mixin.scss" as *;
@use "../../variable.scss" as *;

.quiz {
    @include media501-min {
        display: none;
    }

    @include media500 {
        margin-bottom: 70px;

        .progressMainDiv {
            display: flex;
            justify-content: center;

            .progress {
                max-width: 315px;
                width: 100%;
                background-color: #000000;
                margin-bottom: 20px;
                border-radius: 4px;
                max-height: 6px;
                height: 100%;

                .progress-bar {
                    height: 20px;
                    background-color: #EF4D92;
                }

            }
        }

        img {
            width: 100%;
            height: auto;
        }

        h2 {
            font-size: 20px;
            font-weight: 700;
            line-height: 1.25;
        }

        p {
            font-family: $primary-font;
            font-size: 14px;
            font-weight: 400;
            line-height: 1.36;
            margin-top: 11px;
        }

        .btndiv {
            margin-bottom: 10px;
            display: flex;
            justify-content: center;
        }

        .btncommon {
            max-width: 152px;
            width: 100px;
            padding: 7.04px 0px;
            font-size: 14px;
            font-weight: 700;
            line-height: 1.71;
            border-radius: 50px;
        }

        .centerCommon {
            display: flex;
            text-align: center;
            flex-direction: column;
            align-items: center;
        }

        .widthCommon {
            max-width: 270px;
            width: 100%;
        }

        .row {
            margin-right: 0px;
            margin-left: 0px;
        }

        .quizIntro {
            &__info {
                padding: 0px 24px 0px 30px;
                margin-top: 20px;

                h2 {
                    font-size: 32px;
                }

                p {
                    margin-top: 11px;
                }

                &-btn {
                    display: flex;
                    justify-content: center;

                    button {
                        width: 100%;
                        margin-top: 57px;
                        height: 40px;
                        line-height: 1.7;
                    }

                }
            }
        }

        .quizStart {
            &__info {
                margin-top: 37px;

                h2 {
                    text-transform: uppercase;
                    font-size: 20px;
                }

                input[type='text'] {
                    font-weight: 400;
                    font-size: 14px;
                    margin-top: 35px;
                    max-width: 185px;
                    width: 100%;
                    padding: 10px;
                }

                .nameBtnDiv {
                    margin-top: 30px;
                }

            }
        }


        .hairState {
            min-height: 113dvh;

            &-type {
                margin-top: 20px;
                padding: 0px 20px;

                .radio-input {
                    display: none;
                }

                .col-6 {
                    margin-bottom: 15px;

                    img {
                        border-radius: 13px;
                    }

                    h5 {
                        margin-top: 13px;
                        font-size: 12px;
                        font-weight: 800;
                        text-transform: uppercase;
                    }

                }
            }

            .btndiv {
                button {
                    margin-top: 20px;
                }
            }
        }

        .hairConcerns {

            &-type {
                margin-top: 20px;
                padding: 0px 20px;

                .col-6 {
                    display: flex;
                    justify-content: center;

                    .text {
                        text-transform: uppercase;
                        margin-bottom: 30px;
                        border: 1.5px solid #EF4D92;
                        display: flex;
                        justify-content: center;
                        flex-wrap: wrap;
                        max-width: 136px;
                        font-size: 12px;
                        font-weight: 800;
                        border-radius: 13px;
                        width: 100%;
                        color: #000000;
                        background-color: transparent;
                        line-height: 1.3;
                        align-items: center;
                        height:50px;
                    }


                }
            }
        }

        .hairLength {
            &-type {
                margin-top: 20px;
                padding: 0px 20px;

                .radio-input {
                    display: none;
                }

                .col-6 {
                    margin-bottom: 15px;

                    img {
                        border-radius: 13px;
                    }

                    h5 {
                        margin-top: 13px;
                        font-size: 12px;
                        font-weight: 800;
                        text-transform: uppercase;
                    }

                }
            }


        }

        .lifeStyle {
            width: 100%;
            min-height: 65vh;
            display: flex;
            flex-direction: column;
            -webkit-justify-content: flex-end;
            justify-content: space-between;

            &-titles {
                h2 {
                    text-transform: uppercase;
                }
            }

            &-type {
                max-width: 375px;
                width: 100%;
                display: flex;
                padding: 0px 30px;
                justify-content: space-between;
                top: 50%;

                .text {
                    color: #000000;
                    border: 1.5px solid #EF4D92;
                    display: flex;
                    justify-content: center;
                    flex-wrap: wrap;
                    padding: 16.21px 10px;
                    max-width: 136px;
                    font-size: 12px;
                    font-weight: 800;
                    border-radius: 13px;
                    width: 100%;
                    background-color: transparent;
                    text-transform: uppercase;
                    line-height: 1.3;
                }
            }

            .btndiv {
                bottom: 0;
            }
        }

        .lifeStyle2,
        .lifeStyle3,
        .productSelect {
            &-titles {
                h2 {
                    text-transform: uppercase;
                }
            }

            &-type {
                width: 100%;
                padding: 0px 119px;
                display: flex;
                flex-direction: column;
                align-items: center;

                .text {
                    max-width: 136px;
                    width: 100%;
                    height: 50px;
                    color: #000000;
                    margin-top: 39px;
                    border: 1.5px solid #EF4D92;
                    display: flex;
                    justify-content: center;
                    flex-wrap: wrap;
                    font-size: 12px;
                    font-weight: 800;
                    border-radius: 13px;
                    background-color: transparent;
                    line-height: 1.3;
                    display: flex;
                    align-items: center;
                    text-transform: uppercase;

                }
            }
        }

        .lifestyle2Btn,
        .lifestyle3Btn {
            margin-top: 30px;
        }

        .productSelect {
            padding: 0px 20px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            min-height: 65vh;

            .dropdown-toggle::after {
                position: absolute;
                right: 4%;
                top: 70%;
            }

            .dropdown {
                width: 100%;

                label {
                    font-size: 13px;
                }

                .drp {
                    width: 100%;

                    .btn-check:focus+.btn,
                    .btn:focus {
                        box-shadow: none !important;
                    }

                    button {
                        text-align: left;
                        background: white;
                        border: 1px solid #a99f9f;
                        width: 100%;
                        font-size: 14px;

                    }

                    ul {
                        width: 100%;

                        li {
                            font-size: 13px;
                        }
                    }
                }
            }
        }
    }


}

.modalView {
    @include media501-min {
        display: none;
    }

    @include media500 {
        .footer {
            justify-content: end;
            display: flex;
            margin-bottom: 10px;

            .btnRadius {
                margin-right: 15px;
                border-radius: 50px;
            }
        }
    }

}