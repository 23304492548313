@mixin media500 {
    @media only screen and (max-width: 500px) {
        @content;
    }
}

@mixin media501-min {
    @media only screen and (min-width: 501px) {
        @content;
    }
}

@mixin media768 {
    @media only screen and (max-width:768px) {
        @content;
    }
}

@mixin media932 {
    @media only screen and (max-width:932px) {
        @content;
    }
}
@mixin media932-min {
    @media only screen and (min-width:932px) {
        @content;
    }
}