@use "../../mixin.scss" as *;
@use "../../variable.scss" as *;
.appFooter {

    @include media501-min {
        display: none;
    }

    @include media500 {
        margin-top: auto;
        position: fixed;
        bottom: 0;
        box-shadow: 0px -3px 15px 0px rgba(0, 0, 0, 0.1019607843);
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        z-index: 2;
        background: #F9F2ED;

        &-info {
            width: 100%;
            display: flex;
            justify-content: space-between;
            padding: 10px 30.75px 21.64px 29.76px;

            .dataDiv {
                cursor: pointer;
                display: block;

                img {
                    max-width: 22px;
                    width: 100%;
                    max-height: 22px;
                    height: 100%;
                }

                .thirdImg {
                    max-width: 42px;
                    width: 100%;
                    max-height: 34px;
                    height: 100%;
                }

            }

        }

        .scrollbar {
            position: absolute;
            bottom: 0;
            max-width: 139px;
            width: 100%;
            height: 5px;
            border-radius: 2.5px;
            background-color: #000000;
        }

    }
}