@use "../../mixin.scss" as *;
@use "../../variable.scss" as *;

.header {
    @include media501-min {
        display: none;
    }

    @include media500 {
        display: flex;
        justify-content: center;

        img {
            margin-top: 8px;
            margin-bottom: 20px;
            max-width: 55.54px;
            width: 100%;
            max-height: 28px;
            height: 100%;
        }
    }
}