@use "./mixin.scss" as *;
@use "./variable.scss" as *;

body {
    background-color: #F9F2ED;
    font-family: $primary-font;
    color: #000000;

    .App {
        display: flex;
        flex-direction: column;
        min-height: 100dvh;
    }

    h1,
    h2 {
        font-family: $secondary-font;
    }

    .btn-back {
        color: #000000;
        margin-right: 10px;
    }

    .btn {
        border: 1px solid #000000;
        background-color: transparent;
    }

    .btn1 {
        border: 1px solid #000000;
        background-color: transparent;
    }

    .btn-bg {
        border: 1px solid #000000;
        color: #F9F2ED;
        background-color: #000000;
        border: none;
    }

    input[type='text'] {
        border: 1.5px solid #EF4D92;
        background-color: transparent;
        border-radius: 13px;
    }

    .radio-input {
        opacity: 0;
        cursor: pointer;
    }

    .custom-radio {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        font-size: 16px;
    }

    .radio-checkmark {
        height: 23px;
        width: 23px;
        border-radius: 50%;
        border: 1.5px solid black;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .checkmark {
        display: none;
    }

    .radio-input:checked+.radio-checkmark {
        background-color: transparent;
        border-color: transparent;
        height: 23px;
        width: 23px;
    }

    .radio-input:checked+.radio-checkmark .checkmark {
        display: block;
    }
}