@import "../../mixin.scss";
@import "../../variable.scss";

.mainView {
    display: flex;
    justify-content: center;

    .desktopView {

        .portraitMode {
            display: none;

            @include media932 {
                display: none;
                @include media501-min {
                    width: 100%;
                    box-sizing: border-box;
    
                    @media screen and (orientation: portrait) {
                        display: flex;
    
                        img {
                            width: 100%;
                            height: auto;
                        }
                    }
    
                }
            }

        }

        .deskViewOnly {
            display: none;
            width: 100%;
            box-sizing: border-box;

            @include media932-min {
                display: flex;

                img {
                    width: 100%;
                    height: auto;
                }
            }
        }
    }

    .landScapeView {
        display: none;

        @include media932 {

            @media screen and (orientation: landscape) {
                display: flex;

                img {
                    width: 100%;
                    height: auto;
                }
            }
        }
    }
}