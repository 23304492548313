/* styles.css or your main CSS file */
@font-face {
    font-family: 'Marselis';
    src: url('./assests/fonts/Marselis/MarselisSerifPro.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Avenir';
    src: url('./assests/fonts//Avenir/Avenir-Medium.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}


$primary-font: "Avenir";
$secondary-font: "Marselis";