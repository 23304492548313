@use "../../mixin.scss" as *;
@use "../../variable.scss" as *;

.preparing {
    @include media501-min {
        display: none;
    }

    @include media500 {
        display: flex;
        flex-direction: column;
        align-items: center;

        img {
            width: 100%;
            height: auto;
        }

        &-info {
            margin-top: 20px;
            text-align: center;

            h1 {
                font-weight: 700;
                text-transform: uppercase;
                font-size: 32px;
                line-height: 1.25;

            }

            p {
                font-family: $primary-font;
                margin-top: 23px;
                font-weight: 400;
                font-size: 20px;
                line-height: 0.86;
            }
        }

        .loader {
            margin-top: 94px;
            --d: 38px;
            width: 9px;
            height: 9px;
            border-radius: 100%;
            color: #EF4D92;
            box-shadow:
                calc(1*var(--d)) calc(0.1*var(--d)) 0 0,
                calc(0.707*var(--d)) calc(0.707*var(--d)) 0 1px,
                calc(0*var(--d)) calc(1*var(--d)) 0 2px,
                calc(-0.707*var(--d)) calc(0.707*var(--d)) 0 3px,
                calc(-1*var(--d)) calc(0*var(--d)) 0 4px,
                calc(-0.707*var(--d)) calc(-0.707*var(--d))0 4.6px,
                calc(0*var(--d)) calc(-1*var(--d)) 0 5.7px,
                calc(0.8*var(--d)) calc(-0.6*var(--d)) 0 6.4px;
            animation: l27 3s infinite steps(8);
        }

        @keyframes l27 {
            100% {
                transform: rotate(1turn)
            }
        }
    }
}

.getResult {
    padding: 0px 29px;
    margin-bottom: 70px;
    @include media501-min {
        display: none;
    }

    @include media500 {

        &-backBtn {
            display: flex;
            margin-bottom: 15px;

            img {
                max-width: 15px;
                width: 100%;
                height: auto;
                margin-right: 5px;
            }

            p {
                font-size: 12px;
                font-weight: 400;
            }
        }

        .info {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            padding: 0px 24px;

            h2 {
                font-weight: 700;
                text-transform: uppercase;
                font-size: 20px;
                line-height: 1.25;
                letter-spacing: 1px;
            }

            p {
                margin-top: 23px;
                font-weight: 400;
                font-size: 14px;
                line-height: 1.3;
            }
        }

        .wave {
            width: 100%;
            margin-top: 31px;

            .line {
                overflow: hidden;
                width: 100%;
            }

            .circle {
                padding: 7px 15px;
                background-color: #EF4D92;
                color: white;
                position: absolute;
                left: 46%;
                border-radius: 50%;
                margin-top: -29px
            }
        }

        &-titles {
            margin-top: 31px;
            padding-left: 7px;
            padding-right: 7px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align: center;

            h3 {
                text-transform: uppercase;
                font-weight: 700;
                font-family: $secondary-font;

                span {
                    color: #EF4D92;
                }
            }
        }

        .productMargin {
            margin-top: 59px;
        }

        .cleanse,
        &__Product {
            h2 {
                margin-top: 19px;
                font-size: 20px;
                font-weight: 700;
                line-height: 1.25;
            }

            &-info {
                display: flex;
                margin-top: 18px;

                .left {
                    max-width: 37.856%;
                    width: 100%;

                    img {
                        width: 100%;
                        height: auto;
                        object-fit: cover;
                    }

                    .starRating {
                        display: flex;
                        margin-top: 20px;

                        span {
                            display: flex !important;
                            align-items: center;
                        }

                        p {
                            margin-left: 4px;
                            font-size: 12px;
                        }
                    }

                    .rev {
                        font-weight: 400;
                        color: #EF4D92;
                        font-size: 12px;
                    }
                }

                .right {
                    max-width: 58.995%;
                    width: 100%;
                    margin-left: 20px;

                    .titles {
                        h4 {
                            font-size: 16px;
                            font-weight: 700;
                            font-family: $secondary-font;
                            line-height: 1.25;
                        }

                        p {
                            font-size: 10px;
                            font-weight: 400;
                            line-height: 1.2;
                        }
                    }

                    .use,
                    .ingredients {
                        margin-top: 36px;

                        h4 {
                            font-size: 12px;
                            text-transform: uppercase;
                            font-weight: 800;
                            font-family: $secondary-font;
                            line-height: 1.12;
                        }

                        p {
                            font-size: 12px;
                            font-weight: 400;
                            line-height: 1.2;

                        }
                    }

                    .ingredients {
                        margin-top: 23px;
                    }

                }
            }

            .addToBag {
                margin-top: 29px;
                padding: 12px 0px;
                display: flex;
                justify-content: space-between;
                border-top: 1px solid black;
                border-bottom: 1px solid black;

                .price {
                    margin-left: 38px;
                    font-size: 16px;
                    font-weight: 700;

                }

                .bag {
                    display: flex;
                    font-weight: 800;

                    .custom-radio {
                        margin-right: 6px;
                    }

                    p {
                        margin-right: 13px;
                    }

                    img {
                        max-width: 20px;
                        width: 100%;
                        max-height: 22px;
                        height: 100%;
                    }
                }
            }

            &-addToBag {
                margin-top: 16px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                text-align: center;
                align-items: center;
                font-weight: 700;

                .price {
                    font-size: 16px;
                    border-bottom: 1px solid black;
                    max-width: 188px;
                    width: 100%;
                }

                
                .bag {
                    display: block;
                    margin-top: 12px;


                    &-selectRadio {
                        display: flex;

                        .custom-radio {
                            margin-right: 6px;
                        }


                        img {
                            max-width: 20px;
                            width: 100%;
                            max-height: 22px;
                            height: 100%;
                        }
                    }

                }
            }

            }

        .guest {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;

            &-btn {
                text-transform: uppercase;
                border-radius: 50px;
                font-size: 14px;
                margin-top: 30px;
                text-transform: uppercase;
                border-radius: 50px;
                padding: 7px 0px;
                max-width: 283px;
                width: 100%;
                font-weight: 800;
            }

            .guestCheckout {
                width: 100%;

                h6 {
                    text-transform: uppercase;
                    margin-top: 30px;
                    font-weight: 700;
                }

                h5 {
                    font-weight: bold;
                    margin-top: 2px;
                    text-transform: uppercase;
                    font-family: $secondary-font;
                    font-size: 20px;

                    span {
                        color: #EF4D92;
                    }
                }

                p {
                    font-weight: 700;
                    text-transform: uppercase;
                    font-size: 16px;
                    margin-top: 27px;
                }
            }

            .saveProduct {
                h4 {
                    font-weight: bold;
                    text-transform: uppercase;
                    font-family: $secondary-font;
                    margin-top: 23px;
                }

                p {
                    font-size: 14px;
                    margin-top: 11px;

                    span {
                        font-weight: bold;
                    }
                }

                img {
                    width: 100%;
                    height: auto;
                    margin-top: 20px;
                }

                button {
                    margin-bottom: 20px;
                }


            }
        }
    }

}